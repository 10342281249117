<template>
    <div class="huifu">
        <div class="table">
            <el-table
                :data="list"
                style="width: 100%"
                size="small"
                height="100%"
            >
                <el-table-column prop="nickName" label="评论者" width="200">
                    <template slot-scope="scope">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="scope.row.nickName"
                            placement="top"
                        >
                            <div class="ellipsis">{{ scope.row.nickName }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="评论内容">
                    <template slot-scope="scope">
                        <div class="ellipsis">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="scope.row.content"
                                placement="top"
                            >
                                <div class="ellipsis">
                                    {{ scope.row.content }}
                                </div>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="所属视频" width="100" align="center">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="openUrl(scope.row.accountVideo.videoUrl)"
                            size="mini"
                            >查看</el-button
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    prop="diggCount"
                    label="点赞数"
                    width="100"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="replyCommentTotal"
                    label="回复数"
                    width="100"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="checkReply(scope.row)"
                            size="mini"
                            >{{ scope.row.replyCommentTotal }}</el-button
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="评论时间" width="200">
                    <template slot-scope="scope">
                        {{
                            moment(scope.row.createTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            )
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="updateTime" label="更新时间" width="200">
                    <template slot-scope="scope">
                        {{
                            moment(scope.row.updateTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            )
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="edit" label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="reply(scope.row.id)"
                            size="mini"
                            >回复</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page_box">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="total"
                @current-change="changePage"
                small
            >
            </el-pagination>
        </div>
        <el-drawer
            :title="`${reply_data.nickName}的评论回复记录`"
            :visible.sync="isOpen"
            direction="rtl"
        >
            <div class="reply_box">
                <div
                    class="infinite-list"
                    v-infinite-scroll="load"
                    :infinite-scroll-delay="500"
                    style="overflow: auto; height: 500px"
                    :infinite-scroll-disabled="reply_data.disabled"
                    :before-close="handleClose"
                >
                    <el-empty
                        v-if="!reply_data.list.length && !loading"
                        description="此评论还没有人回复哦~点击下面按钮回复一下呗~"
                    >
                        <el-button type="primary" size="mini" @click="reply()"
                            >回复</el-button
                        >
                    </el-empty>
                    <div class="reply_group">
                        <div
                            class="reply_list"
                            v-for="(item, index) in reply_data.list"
                            :key="index"
                        >
                            <div class="reply_content">
                                {{ item.content }}
                            </div>
                            <div class="timer">
                                {{
                                    moment(updateTime).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="tis_box">
                        <div class="loading" v-if="loading">
                            <i class="el-icon-loading"></i> 正在加载
                        </div>
                        <div
                            class="end"
                            v-if="reply_data.disabled && reply_data.list.length"
                        >
                            小哥哥小姐姐们~到底了~
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { getCommentList, replyComment, getReplyList } from "@/api/api";

import moment from "moment";
export default {
    name: "Letter",

    data() {
        return {
            moment,
            isOpen: false,
            list: [],
            where: {
                authorizedUserId: "", //用户ID
                content: "", //内容
                current: 1, //页码
                nickName: "", //用户昵称
                size: 10, //每页条数
                videoId: "", //视频ID
                "orders[0].asc": false,
                "orders[0].column": "update_time",
            },
            total: 0,
            reply_data: {
                where: {
                    size: 20,
                    current: 1,
                    "orders[0].asc": false,
                    "orders[0].column": "update_time",
                    parentCommentId:
                        "@9VwC3+qHC9o8OSa5etEoSM7912zgP/6EOpd5oA+uLlUWbPj160zdRmYqig357zEBNeqxGwDGKgeRk+D6Q2b2KQ==",
                },
                disabled: true,
                total: 0,
                list: [],
                nickName: "",
            },
            count: 0,
            loading: false,
        };
    },

    mounted() {
        this.getList();
    },

    methods: {
        load() {
            this.where.current += 1;
        },
        checkReply(data) {
            this.reply_data.where.parentCommentId = data.id;
            this.isOpen = true;
            this.reply_data.disabled = false;
            this.reply_data.nickName = data.nickName;
            this.getReply();
        },
        handleClose() {
            this.reply_data.where.parentCommentId = "";
            this.reply_data.list = [];
            this.reply_data.disabled = false;
            this.reply_data.nickName = true;
        },
        async getReply() {
            this.loading = true;
            let res = await getReplyList(this.reply_data.where);
            this.loading = false;
            if (res.code == 200) {
                this.reply_data.total = res.data.total;
                let list = this.reply_data.list;
                this.reply_data.list = [...list, ...res.data.records];

                if (res.data.pages <= this.reply_data.where.current) {
                    console.log("end");
                    // 停止自动加载
                    this.reply_data.disabled = true;
                }
            }
        },
        changePage(current) {
            this.where.current = current;
            this.getList();
        },
        getList() {
            let where = this.where;
            getCommentList(where).then((res) => {
                if (res.code == 200) {
                    this.list = res.data.records;
                    this.total = res.data.total;
                }
            });
        },
        reply(id) {
            let reply_id = id || this.reply_data.where.parentCommentId;
            this.$prompt("请输入输入回复内容", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(({ value }) => {
                let json = {
                    commentId: reply_id,
                    content: value,
                };
                replyComment(json).then((res) => {
                    if (res.code == 200) {
                        this.$message.success("回复成功");
                    }
                });
            });
        },
        openUrl(url) {
            window.open(url);
        },
    },
};
</script>

<style lang="less" scoped>
.huifu {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .table {
        flex: 1;
        background-color: red;
    }
}
.reply_box {
    height: 600px;
}
.reply_group {
    padding: 0 20px;
    .reply_list {
        margin-bottom: 10px;
    }
    .reply_content {
        margin-bottom: 8px;
    }
    .timer {
        text-align: right;
    }
}
.tis_box {
    text-align: center;
    padding: 20px;
    color: #b3b3b3;
}
</style>